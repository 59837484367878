// AiDevelopment.tsx

import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { motion } from "framer-motion";
import AiBlob from "./AiBlob";

const AiDevelopment = () => {
	return (
		<Box
			sx={{
				height: "100vh",
				background: "black",
				display: "flex",
				alignItems: "center",
				width: "100%",
				px: 4,
				borderTopColor: "gray",
				borderTopWidth: 0.5,
			}}
		>
			<Box sx={{ width: "100%" }}>
				<Grid2 container alignItems="center">
					{/* Left Section with text */}
					<Grid2 size={{ xs: 12, md: 5 }}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.5 }}
						>
							<Typography
								variant="h2"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								AI-Powered Solutions
							</Typography>
							<Typography
								variant="h6"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
									py: 1,
								}}
							>
								Harness the power of AI to transform your
								business. From automating workflows to
								delivering personalized experiences, our AI
								solutions are designed to unlock new
								possibilities. At QuickLoop, we specialize in
								building intelligent systems that optimize
								processes, enhance customer engagement, and
								create lasting value. Let’s shape the future
								together.
							</Typography>
							<Box sx={{ marginTop: 2 }}>
								<Grid2 container spacing={2}>
									{/* First Column */}
									<Grid2 size={{ xs: 6 }}>
										<Typography
											variant="body1"
											sx={{ color: "white" }}
										>
											Custom AI Models
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Process Automation
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Natural Language Processing
										</Typography>
									</Grid2>

									{/* Second Column */}
									<Grid2 size={{ xs: 6 }}>
										<Typography
											variant="body1"
											sx={{ color: "white" }}
										>
											AI-Powered Analytics
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Predictive Solutions
										</Typography>
										<Typography
											variant="body1"
											sx={{ color: "white", mt: 1 }}
										>
											Chatbots & Virtual Assistants
										</Typography>
									</Grid2>
								</Grid2>
							</Box>
						</motion.div>
					</Grid2>

					{/* Right Section with SVG animation */}
					<Grid2 size={{ xs: 12, md: 7 }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<AiBlob />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default AiDevelopment;
