import React from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";

const AnimatedBlob = () => {
	const isSmallScreen = useMediaQuery("(max-width:600px)");

	return (
		<motion.svg
			viewBox="0 0 200 200"
			xmlns="http://www.w3.org/2000/svg"
			initial={{ scale: 1 }}
			animate={{ scale: isSmallScreen ? 4 : 8 }} // Adjust scale for smaller screens
			transition={{
				duration: 3,
			}}
			style={{
				width: isSmallScreen ? "150px" : "200px",
				height: isSmallScreen ? "150px" : "200px",
			}}
		>
			<motion.path
				fill="white"
				d="M46.6,-53.9C59,-55.1,66.8,-39.6,67.4,-24.9C68,-10.2,61.5,3.9,53,13.2C44.5,22.6,33.9,27.4,24.7,38.3C15.5,49.2,7.8,66.3,2.3,63.2C-3.2,60.1,-6.4,36.7,-20,27.2C-33.6,17.7,-57.5,22.1,-58.1,19.1C-58.7,16.2,-36,6,-31.4,-10.9C-26.8,-27.7,-40.5,-51.1,-38.3,-53.2C-36.1,-55.3,-18,-36.1,-0.5,-35.4C17.1,-34.8,34.1,-52.6,46.6,-53.9Z"
				transform="translate(100 100)"
			/>
		</motion.svg>
	);
};

export default AnimatedBlob;
