// OpenContactButton.tsx

import React, { useState } from "react";
import { Button } from "@mui/material";
import ContactDialog from "./ContactDialog";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RECAPTCHA_SITE_KEY = "6LecY18qAAAAALZzIgqTT-8rHEe0ziAHFLGCIMG0";
// const RECAPTCHA_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; // Test Key

interface OpenContactButtonProps {
	color?: string;
}

const OpenContactButton: React.FC<OpenContactButtonProps> = ({
	color = "black",
}) => {
	const [isDialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);
	return (
		<>
			<Button
				variant="text"
				color="primary"
				onClick={handleOpenDialog}
				sx={{ color: color }}
			>
				Contact Us
			</Button>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
				<ContactDialog
					open={isDialogOpen}
					handleClose={handleCloseDialog}
				/>
			</GoogleReCaptchaProvider>
		</>
	);
};

export default OpenContactButton;
