// MeetTheTeam.tsx

import {
	Box,
	Typography,
	Grid2,
	Card,
	CardContent,
	IconButton,
	CardMedia,
	Link,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn"; // LinkedIn Icon
import gunjan from "../assets/images/co-founders/gunjan.jpeg";
import saurabh from "../assets/images/co-founders/saurabh.jpeg";
import varun from "../assets/images/co-founders/varun.jpeg";

const teamData = [
	{
		name: "Gunjan Malani",
		position: "CMO, Co-Founder",
		bio: "Full-stack developer with 8+ years of experience in web and cloud solutions.",
		photo: gunjan,
		linkedin: "https://www.linkedin.com/in/gunjan-malani/", // LinkedIn URL
	},
	{
		name: "Saurabh Pabhare",
		position: "Co-Founder",
		bio: "Creative designer with a passion for intuitive user experiences.",
		photo: saurabh,
		linkedin: "https://www.linkedin.com/in/saurabh-pabhare/",
	},
	{
		name: "Varun Rathore",
		position: "Co-Founder",
		bio: "Expert in CI/CD pipelines and cloud infrastructure management.",
		photo: varun,
		linkedin: "https://www.linkedin.com/in/varun-rathore/",
	},
];

const MeetTheTeam = () => {
	return (
		<Box
			sx={{
				height: "100vh",
				background: "black",
				display: "flex",
				alignItems: "center",
				width: "100%",
				px: 4,
				borderTopColor: "gray",
				borderTopWidth: 0.5,
			}}
		>
			<Grid2 container alignItems="center" className="w-full">
				<Grid2 size={{ xs: 12, md: 4 }}>
					<Typography
						variant="h2"
						sx={{
							textAlign: "center",
							mb: 4,
							fontWeight: "bold",
							color: "#4d40f4",
						}}
					>
						Meet Our Team
					</Typography>
				</Grid2>

				<Grid2
					size={{ xs: 12, md: 8 }}
					display="flex"
					flexDirection="row"
					justifyContent="center"
				>
					{teamData.map((member, index) => (
						<Grid2 size={{ xs: 12, sm: 6, md: 3 }} key={index}>
							<Card
								sx={{
									width: 200, // Set consistent width
									height: 500, // Set consistent height
									margin: "0 auto",
									boxShadow: 3,
									borderRadius: 2,
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
							>
								<CardMedia
									component="img"
									image={member.photo}
									alt={member.name}
									sx={{
										height: 300,
										width: "100%",
										objectFit: "cover",
									}}
								/>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography
										variant="h5"
										align="center"
										fontWeight="bold"
									>
										{member.name}
									</Typography>
									<Typography
										variant="subtitle1"
										align="center"
										color="textSecondary"
										gutterBottom
									>
										{member.position}
									</Typography>

									{/* LinkedIn Icon */}
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											marginTop: 1,
										}}
									>
										<Link
											href={member.linkedin}
											target="_blank"
											rel="noopener noreferrer"
										>
											<IconButton color="primary">
												<LinkedInIcon />
											</IconButton>
										</Link>
									</Box>
								</CardContent>
							</Card>
						</Grid2>
					))}
				</Grid2>
			</Grid2>
		</Box>
	);
};

export default MeetTheTeam;
