// App.tsx

import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

const circleStyle: React.CSSProperties = {
	width: 200,
	height: 200,
	borderRadius: "50%",
	backgroundColor: "#4d40f4",
	position: "absolute",
};

const containerStyle: React.CSSProperties = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	height: "100vh",
	width: "100vw",
	position: "relative",
};

const WebDebBlob: React.FC = () => {
	const ref = useRef(null); // Reference to the section
	const isInView = useInView(ref);
	return (
		<div ref={ref} style={containerStyle}>
			{/* Circles */}
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={isInView ? { x: -100, y: -100 } : { x: 0, y: 0 }}
				transition={{ duration: 1 }}
			/>
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={isInView ? { x: 100, y: -100 } : { x: 0, y: 0 }}
				transition={{ duration: 1 }}
			/>
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={isInView ? { x: -100, y: 100 } : { x: 0, y: 0 }}
				transition={{ duration: 1 }}
			/>
			<motion.div
				style={circleStyle}
				initial={{ x: 0, y: 0 }}
				animate={isInView ? { x: 100, y: 100 } : { x: 0, y: 0 }}
				transition={{ duration: 1 }}
			/>
		</div>
	);
};

export default WebDebBlob;
