import React, { useState, useEffect, ReactNode } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import WebMobileDevelopment from "./components/WebMobileDevelopment";
import Footer from "./components/Footer";
import AiDevelopment from "./components/AiDevelopment";
import DevOpsComponent from "./components/DevOpsComponent";
import MeetTheTeam from "./components/MeetTheTeam";

interface PageProps {
	id?: string;
	children: ReactNode;
}

const Page: React.FC<PageProps> = ({ id, children }) => (
	<section
		id={id}
		className="w-full flex items-center justify-center snap-start"
	>
		{children}
	</section>
);

const App: React.FC = () => {
	const [currentPage, setCurrentPage] = useState<number>(0);

	useEffect(() => {
		const handleScroll = (): void => {
			const scrollPosition = window.scrollY;
			const windowHeight = window.innerHeight;
			const currentPage = Math.round(scrollPosition / windowHeight);
			setCurrentPage(currentPage);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const scrollTo = (page: number): void => {
		window.scrollTo({
			top: page * window.innerHeight,
			behavior: "smooth",
		});
	};

	return (
		<div className="App">
			<Header />
			<div className="h-screen overflow-y-scroll snap-y snap-mandatory">
				<Page id="home">
					<HeroSection />
				</Page>
				<Page id="services">
					<WebMobileDevelopment />
				</Page>
				<Page>
					<AiDevelopment />
				</Page>
				<Page>
					<DevOpsComponent />
				</Page>
				<Page id="team">
					<MeetTheTeam />
				</Page>
				<Page>
					<Footer />
				</Page>
			</div>
			{/* <nav className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
				{[0, 1, 2].map((page) => (
					<button
						key={page}
						onClick={() => scrollTo(page)}
						className={`w-3 h-3 rounded-full ${
							currentPage === page ? "bg-white" : "bg-gray-400"
						}`}
					/>
				))}
			</nav> */}
		</div>
	);
};

export default App;
