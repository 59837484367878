import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { motion } from "framer-motion";
import AnimatedBlob from "./AnimatedBlob";

const HeroSection = () => {
	return (
		<Box
			sx={{
				height: "100vh",
				background: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
				display: "flex",
				alignItems: "center",
				width: "100%",
				px: 4,
			}}
		>
			<Box sx={{ width: "100%" }}>
				<Grid2 container alignItems="center">
					{/* Left Section with text */}
					<Grid2 size={{ xs: 12, md: 4 }}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1.5 }}
						>
							<Typography
								variant="h2"
								sx={{
									color: "white",
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								<i>Innovating</i> the Future of Technology
							</Typography>
							<Typography
								variant="h5"
								sx={{
									color: "white",
									textAlign: "left",
									marginTop: 2,
								}}
							>
								We <i>build</i> digital solutions that{" "}
								<i>empower</i> businesses to scale with
								confidence, using cutting-edge technology and
								AI-driven innovation.
							</Typography>
						</motion.div>
					</Grid2>

					{/* Right Section with SVG animation */}
					<Grid2 size={{ xs: 12, md: 8 }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<AnimatedBlob />
						</Box>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	);
};

export default HeroSection;
