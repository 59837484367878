// theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	typography: {
		fontFamily: "Nunito, serif", // Global font setting
		h1: {
			fontFamily: "Nunito, serif",
			fontWeight: 700,
		},
		h2: {
			fontFamily: "Nunito, serif",
			fontWeight: 700,
		},
		h3: {
			fontFamily: "Nunito, serif",
			fontWeight: 700,
		},
		body1: {
			fontFamily: "Nunito, serif",
			fontWeight: 400,
		},
		body2: {
			fontFamily: "Nunito, serif",
			fontWeight: 400,
		},
	},
});

export default theme;
