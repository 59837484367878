import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import OpenContactButton from "./OpenContactButton";
import quickloopLogoLight from "../assets/logos/quickloop-light.svg";
// import quickloopLogoDark from "../../assets/logos/quickloop-dark.svg";

const Header: React.FC = () => {
	const scrollToSection = (id: string) => {
		const section = document.querySelector(id);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};
	return (
		<AppBar
			position="fixed"
			sx={{ color: "black", backgroundColor: "white" }}
			elevation={1}
		>
			<Box sx={{ width: "100%", px: 4 }}>
				<Toolbar disableGutters>
					{/* <Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ flexGrow: 1 }}
					>
						QuickLoop
					</Typography> */}
					<a
						href="/"
						className="navbar-brand"
						style={{ flexGrow: 1 }}
					>
						<img
							src={quickloopLogoLight}
							alt="Quickloop"
							className="navbar-logo h-8 transition-all duration-300"
						/>
					</a>
					<Button
						color="inherit"
						onClick={() => scrollToSection("#home")}
					>
						Home
					</Button>
					<Button
						color="inherit"
						onClick={() => scrollToSection("#services")}
					>
						Services
					</Button>
					<Button
						color="inherit"
						onClick={() => scrollToSection("#team")}
					>
						Team
					</Button>
					<OpenContactButton />
				</Toolbar>
			</Box>
		</AppBar>
	);
};

export default Header;
