// App.tsx

import React, { useRef } from "react";
import { Box } from "@mui/material";
import { motion, useInView } from "framer-motion";

const circleStyle: React.CSSProperties = {
	width: 220,
	height: 220,
	borderRadius: "50%",
	backgroundColor: "black",
	position: "absolute",
};

const containerStyle: React.CSSProperties = {
	height: 500,
	width: 500,
	backgroundColor: "#39ff14", // Neon green background
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
	overflow: "hidden",
};

const AiBlob: React.FC = () => {
	const ref = useRef(null);
	const isInView = useInView(ref);
	const initialPositions = [
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
		{ x: 0, y: 0 },
	];

	const finalPositions = [
		{ x: -250, y: -250 }, // Top-left
		{ x: 0, y: -250 }, // Top-center
		{ x: 250, y: -250 }, // Top-right
		{ x: -250, y: 250 }, // Bottom-left
		{ x: 0, y: 250 }, // Bottom-center
		{ x: 250, y: 250 }, // Bottom-right
		{ x: -250, y: 0 }, // Left-center
		{ x: 250, y: 0 }, // Right-center
		{ x: 0, y: 0 }, // Center
	];

	return (
		<div style={{ height: "500px" }}>
			{" "}
			{/* To allow scrolling */}
			<Box ref={ref} style={containerStyle}>
				{/* Initial 4 Circles */}
				{initialPositions.map((pos, index) => (
					<motion.div
						key={index}
						style={circleStyle}
						initial={{ x: pos.x, y: pos.y }}
						animate={
							isInView
								? {
										x: finalPositions[index].x,
										y: finalPositions[index].y,
								  }
								: { x: pos.x, y: pos.y }
						}
					/>
				))}

				{/* Extra Circles for final state */}
				{finalPositions.slice(4).map((pos, index) => (
					<motion.div
						key={`extra-${index}`}
						style={circleStyle}
						initial={{ opacity: 0 }}
						animate={
							isInView
								? { opacity: 1, x: pos.x, y: pos.y }
								: { opacity: 0 }
						}
						transition={{ duration: 0.5 }}
					/>
				))}
			</Box>
		</div>
	);
};

export default AiBlob;
